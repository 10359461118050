@import "../../global.scss";

.contact {
  background-color: #f0f4f7;
  display: flex;

  @include mobile {
    flex-direction: column;
  }

  .left {
    flex: 1;
    overflow: hidden;

    .imgContainer {
      width: 800px;
      height: 800px;
      background-color: white;
      border-radius: 50%;
      display: flex;
      align-items: flex;
      justify-content: center;
      float: right;

      @include mobile {
        align-items: flex-start;
        border-radius: 0%;
      }

      img {
        width: 100%;

        @include mobile {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      font-style: 30px;
      letter-spacing: 4px;
    }
    form {
      width: 70%;
      height: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      input {
        width: 400px;
        height: 40px;
        font-size: 14px;

        @include mobile {
          width: 200px;
          height: 20%;
        }
      }
      textarea {
        width: 400px;
        height: 300px;
        font-size: 14px;

        @include mobile {
          width: 200px;
          height: 100%;
        }
      }
      button {
        width: 200px;
        height: 30px;
        color: white;
        background-color: rgba(22, 22, 23, 0.8);
        border: none;
        border-radius: 10px;
        font-weight: 500;
        cursor: pointer;

        &:focus {
          outline: none;
        }
      }
      span {
        color: black;
      }
    }
  }
}
