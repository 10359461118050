@import "../../global.scss";

.intro {
  background-color: #f0f4f7;
  display: flex;

  @include mobile {
    flex-direction: column;
    align-items: center;
  }

  .left {
    flex: 0.5;
    overflow: hidden;

    .imgContainer {
      width: 700px;
      height: 700px;
      background-color: white;
      border-radius: 50%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      float: right;

      @include mobile {
        align-items: flex-start;
      }

      img {
        width: 100%;

        @include mobile {
          height: 70%;
          width: 70%;
        }
      }
    }
  }
  .right {
    flex: 0.5;
    position: relative;
    .wrapper {
      width: 100%;
      height: 100%;
      padding-left: 50px;
      display: flex;
      justify-content: center;
      flex-direction: column;

      @include mobile {
        padding-left: 0;
        align-items: center;
      }

      h1 {
        font-size: 65px;
        margin: 10px, 0;
        letter-spacing: 4px;

        @include mobile {
          font-size: 40px;
        }
      }
      h3 {
        font-size: 17px;
        letter-spacing: 1px;
      }
      h4 {
        font-size: 15px;
        letter-spacing: 1px;
        @include mobile {
          font-size: 20px;
        }

        span {
          font-size: inherit;
          color: black;
        }
        .ityped-cursor {
          animation: blink 1s infinite;
        }
        @keyframes blink {
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
      }
    }
    a {
      position: absolute;
      bottom: 10px;
      left: 40%;

      img {
        width: 30px;
        animation: arrowBlink 2s infinite;
      }
      @keyframes arrowBlink {
        100% {
          opacity: 0;
        }
      }
    }
  }
}
