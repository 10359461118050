@import "../../global.scss";

.portfolio {
  background-color: #f0f4f7;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 50px;
    font-family: sans-serif;
    letter-spacing: 4px;
    padding-top: 15px;

    @include mobile {
      font-size: 20px;
    }
  }

  ul {
    margin: 10px;
    padding: 0;
    list-style: none;
    display: flex;

    @include mobile {
      margin: 10px, 0;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  .container {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @include mobile {
      font-size: 100%;
    }

    .item {
      width: 90%;
      height: 90%;
      border-radius: 20px;
      border: 1px solid lightgrey;
      margin: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      position: relative;
      transition: all 0.5s ease;
      cursor: pointer;

      @include mobile {
        width: 150px;
        height: 120px;
      }

      h3 {
        position: absolute;
        font-size: 20px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
      }
      &:hover {
        background-color: rgba(22, 22, 23, 0.8);
        img {
          opacity: 0.2;
          z-index: 0;
        }
      }
    }
  }
}
