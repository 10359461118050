@import "../../global.scss";

.topbar {
  width: 100%;
  height: 70px;
  background-color: rgba(22, 22, 23, 0.8);
  color: $mainColor;
  position: fixed;
  top: 0;
  z-index: 3;
  transition: all 1 s ease;

  .wrapper {
    padding: 17px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
    }

    // .logo {
    //   font-size: 40px;
    //   font-weight: 700;
    //   text-decoration: none;
    //   color: inherit;
    //   margin-right: 40px;
    // }
    .itemContainer {
      display: flex;
      align-items: center;
      margin-left: 40px;
    }

    .icon {
      font-size: 30px;
      margin-right: 5px;
      color: white;
    }

    .right {
      .hamburger {
        width: 32px;
        height: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
      }
      span {
        width: 100%;
        height: 3px;
        background-color: $mainColor;
        transform-origin: left;
        transition: all 2s ease;
      }
    }
  }

  &.active {
    background-color: $mainColor;
    color: black;
    .hamburger {
      span {
        &:first-child {
          background-color: #000000;
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:last-child {
          background-color: #000000;
          transform: rotate(-45deg);
        }
      }
    }
  }
}
